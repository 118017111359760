export class Platform {

}

export enum PlatformEnum {
  FACEBOOK = "FACEBOOK",
  INSTAGRAM = "INSTAGRAM",
  LINKEDIN = "LINKEDIN",
  X = "X",
  META = "META",
}

export const PlatformLabels: { [key: string]: string } = {
  FACEBOOK: 'Facebook',
  INSTAGRAM: 'Instagram',
  LINKEDIN: 'LinkedIn',
  X: 'X',
  META: 'Meta'
};

export const PlatformKeyValue = Object.entries(PlatformEnum).map(([key, value]) => ({ key: value, value: key }))

export enum PlatformDiagnosticConnectionEnum {
  FACEBOOK_DIAGNOSTICS_CONNECTION = "FACEBOOK_DIAGNOSTICS_CONNECTION",
  META_BUSINESS_DIAGNOSTICS_CONNECTION = "META_BUSINESS_DIAGNOSTICS_CONNECTION",
}
